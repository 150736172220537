%brightness-120 {
    transition: filter .3s ease-in-out;

    &:hover {
        filter: brightness(120%);
    }
}

%get-up {
    transition: box-shadow .3s ease-in-out;

    &:hover {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .1), 0 15px 15px 0 rgba(0, 0, 0, .08);;
    }
}

%animated-underline-base {
    text-decoration: none;
    position: relative;
    transition: filter .3s ease-out;

    &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 2px;
        left: 0;
        height: 1px;
        background-color: currentColor;
        transition: width .3s ease-out;
    }

    &:hover {
        text-decoration: none !important;
        filter: brightness(110%);
        opacity: 1;
    }
}

%animated-underline {
    @extend %animated-underline-base;

    &:before {
        width: 0;
    }

    &:hover {
        &:before {
            width: 100%;
        }
    }
}

%animated-underline-backward {
    @extend %animated-underline-base;

    &:before {
        width: 100%;
    }

    &:hover {
        &:before {
            width: 0;
        }
    }
}

%animated-underline-label-base {
    text-decoration: none;

    .label {
        position: relative;
        transition: filter .3s ease-out;

        &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 2px;
            left: 0;
            height: 1px;
            background-color: currentColor;
            transition: width .3s ease-out;
        }
    }

    &:hover {
        text-decoration: none !important;

        .label {
            filter: brightness(110%);
            opacity: 1;
        }
    }
}

%animated-underline-label {
    @extend %animated-underline-label-base;

    .label {
        &:after {
            width: 100%;
        }
    }

    &:hover {
        .label {
            &:after {
                width: 0;
            }
        }
    }
}

%animated-underline-label-forward {
    @extend %animated-underline-label-base;

    .label {
        &:after {
            width: 0;
        }
    }

    &:hover {
        .label {
            &:after {
                width: 100%;
            }
        }
    }
}
