.ps-about-us {
    margin-block: 50px 100px;

    .inner-container {
        display: flex;
        gap: 50px;
    }

    .inner-wrapper {
        > :first-child {
            margin-top: 0;
        }

        > :last-child {
            margin-bottom: 0;
        }
    }

    .title {
        margin-top: 50px;
        margin-bottom: 20px;
    }

    .info {
        display: flex;
        flex-direction: column;
        gap: .7em;
    }

    a[href] {
        color: $color-red;
    }

    .certificates {
        a {
            img {
                transition: transform .3s ease-in-out;
            }

            &:hover {
                img {
                    transform: translateY(-5px);
                }
            }
        }

        .text {
            font-weight: 700;
            color: $color-black;
        }

        .inner-wrapper {
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            gap: 20px 60px;
            align-items: flex-start;
        }

        .c-1 {
            width: auto;
            height: 65px;
        }

        .c-2 {
            width: auto;
            height: 65px;
        }

        .c-3 {
            width: auto;
            height: 65px;
        }
    }

    .collage {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 20px;

        .inner-wrapper {
            display: flex;
            align-items: flex-start;
            gap: 20px;
            margin-inline: -20px;
        }

        .img-3 {
            margin-top: -106px;
        }

        .img-2,
        .img-3 {
            box-shadow: $shadow;
        }
    }

    @include for-md {
        margin-top: 60px;

        .inner-container {
            flex-direction: column;
        }

        .collage {
            align-items: center;
        }
    }

    @include for-sm {
        margin-top: 40px;

        .title {
            margin-bottom: 10px;
        }

        .certificates {
            margin-top: 30px;

            .inner-wrapper {
                gap: 20px 30px;
            }

            .c-1 {
                height: 60px;
            }

            .c-2 {
                height: 60px;
            }

            .c-3 {
                height: 65px;
            }
        }

        .collage {
            gap: 10px;

            .inner-wrapper {
                gap: 10px;
            }

            .img-1 {
                width: 294px;
                height: 235px;
            }

            .img-2 {
                width: 146px;
                height: 128px;
            }

            .img-3 {
                width: 158px;
                height: 229px;
                margin-top: -55px;
            }
        }
    }
}
