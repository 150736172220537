.ps-home-hero {

    .inner-container {
        position: relative;
    }

    .home-hero-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left 92%;
    }

    .inner-wrapper {
        padding-inline: 30px;
        height: 680px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }

    .field-of-activity {
        padding: 50px;
        background: rgba(23, 23, 28, .9);
        backdrop-filter: blur(5px);
        max-width: 720px;
        border-bottom: 10px solid $color-red;
        margin-bottom: -10px;

        .title,
        .text {
            color: $color-white;
        }

        .title {
            margin-bottom: 20px;
        }
    }

    @include for-md {
        .home-hero-bg {
            position: static;
            height: 680px;
            object-position: left 90%;
        }

        .inner-wrapper {
            height: auto;
        }


        .field-of-activity {
            max-width: none;
            margin-inline: -30px;
        }
    }

    @include for-sm {
        .home-hero-bg {
            object-position: 20% 90%;
        }

        .field-of-activity {
            padding: 30px;

            .title {
                margin-bottom: 10px;
            }
        }

    }

}
