* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    background-color: $color-white;
}

p {
    margin: 0;
}
