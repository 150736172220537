.header {
    padding-block: 50px;

    .site-logotype {
        @extend %brightness-120;

        img {
            display: block;
        }
    }

    .inner-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    @keyframes moving {
        from {
            background-position: 0 0;
        }

        to {
            background-position: 0 85px;
        }
    }

    .footnote {
        @extend %heading;
        line-height: 1.2;
        color: $color-red;
        text-align: right;
        padding-right: 30px;
        position: relative;

        &:after {
            position: absolute;
            right: 0;
            top: 0;
            content: "";
            display: block;
            width: 10px;
            height: 85px;
            background-image: url("../images/uc.svg");
            animation: moving 10s linear infinite;
        }

        em {
            font-style: normal;
            font-weight: 400;
            color: $color-black-text;
        }
    }

    .slogan {
        @extend %heading-32;
        margin: 35px 0 0;
    }

    @include for-md {
        .inner-wrapper {
            flex-direction: column;
            align-items: center;
            gap: 50px;
        }

        .footnote {
            text-align: center;
            padding-right: 0;

            br {
                display: none;
            }

            &:after {
                content: none;
            }
        }

        .slogan {
            margin-top: 50px;
            text-align: center;
        }
    }

    @include for-sm {
        padding-bottom: 30px;
        padding-inline: 20px;

        .site-logotype {
            img {
                width: 260px;
                height: 45px;
            }
        }

        .inner-wrapper {
            gap: 30px;
        }

        .slogan {
            margin-top: 30px;

            br {
                display: none;
            }
        }
    }
}
