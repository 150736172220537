.title {
    @extend %heading;
    margin: 0;
}

.text {
    @extend %text;
    margin: 0;
}

img {
    display: block;
}

.nbsp-container {
    display: inline-flex;
    align-items: center;
    gap: .3em;
}
