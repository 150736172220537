.flag {
    display: inline-block;
    width: 1em;
    height: .7em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: -1px;

    &.de {
        background-image: url("../images/flags/de.svg");
    }

    &.dk {
        background-image: url("../images/flags/dk.svg");
    }

    &.es {
        background-image: url("../images/flags/es.svg");
    }

    &.fr {
        background-image: url("../images/flags/fr.svg");
    }

    &.ge {
        background-image: url("../images/flags/ge.svg");
    }

    &.kz {
        background-image: url("../images/flags/kz.svg");
    }

    &.lv {
        background-image: url("../images/flags/lv.svg");
    }

    &.pl {
        background-image: url("../images/flags/pl.svg");
    }

    &.tr {
        background-image: url("../images/flags/tr.svg");
    }
}
