.ps-featured-clients {
    margin-block: 100px 20px;

    .inner-container {
        background: #F0F6F9;
        backdrop-filter: blur(10px);
        padding: 50px;
    }

    .inner-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 40px 30px;
    }

    .title {
        margin-bottom: 50px;
    }

    .logotype-container {
        @extend %get-up;
        background-color: $color-white;
        box-shadow: $shadow;
        height: 160px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-inline: 10px;

        .logotype {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .text {
        margin-top: 20px;
    }

    @include for-md {
        .inner-container {
            margin-inline: -30px;
        }

        .inner-grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @include for-sm {
        margin-bottom: 10px;

        .title {
            margin-bottom: 30px;
        }

        .inner-container {
            padding: 30px 30px 50px;
        }

        .logotype-container {
            height: 140px;
        }

        .text {
            margin-top: 10px;
        }
    }

    @include for-xs {
        .inner-grid {
            grid-template-columns: 1fr;
            grid-gap: 30px;
        }
    }
}
