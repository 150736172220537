$din2014: "din-2014", sans-serif;

%text {
    font-family: $din2014;
    font-size: 26px;
    font-weight: 400;
    line-height: 1.4;
    color: $color-black-text;

    sup {
        font-size: 16px;
    }

    @include for-sm {
        font-size: 18px;

        sup {
            font-size: 10px;
        }
    }
}

%heading {
    font-family: $din2014;
    font-size: 34px;
    font-weight: 700;
    line-height: 1.4;
    text-transform: uppercase;
    color: $color-black-text;

    @include for-sm {
        font-size: 24px;
    }
}

%heading-32 {
    font-family: $din2014;
    font-size: 32px;
    font-weight: 400;
    line-height: 1.4;
    text-transform: uppercase;
    color: $color-black-text;

    @include for-sm {
        font-size: 24px;
    }
}
