@mixin for-md {
    @media screen and (max-width: 1100px) {
        @content
    }
}

@mixin for-sm {
    @media screen and (max-width: 700px) {
        @content
    }
}

@mixin for-xs {
    @media screen and (max-width: 480px) {
        @content
    }
}
