.ps-section {
    padding-inline: 50px;

    @include for-sm {
        padding-inline: 30px;
    }

    &.expanded .inner-container {
        margin-inline: -30px;
    }

    .ps-content {
        margin-inline: auto;
        max-width: 1340px;
    }
}
