.ps-footer {

    * {
        color: $color-white;
    }

    .inner-container {
        padding: 50px;
        background-color: $color-red;
        position: relative;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 10px;
            right: 10px;
            $size: 62px;
            border: $size solid transparent;
            border-top: $size solid $color-black-text;
            border-right: $size solid $color-black-text;
        }
    }

    .title {
        max-width: 750px;
    }

    .block-contacts {
        margin-top: 50px;
        padding-left: 50px;
    }

    .contacts {
        margin-top: 20px;
        display: flex;
        gap: 110px;

        .inner-wrapper {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }
    }

    .name-position {

    }

    .name {
        font-weight: 700;
    }

    .phone-container,
    .mail,
    .address {
        position: relative;
        cursor: pointer;

        &:after {
            content: "";
            position: absolute;
            display: block;
            width: 30px;
            height: 30px;
            left: -50px;
            top: 0;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    .phone {
        @extend %animated-underline;
        text-decoration: none;
    }

    .phone-container {
        display: flex;
        gap: 10px;

        &:after {
            background-image: url("../images/phone.svg");
        }
    }

    .mail {
        @extend %animated-underline-backward;
        max-width: max-content;
        &:after {
            background-image: url("../images/mail.svg");
            top: 5px;
        }
    }

    .address {
        text-decoration: none;
        &:after {
            transition: transform .3s ease-in-out;
            background-image: url("../images/address.svg");
        }

        &:hover {
            &:after {
                transform: rotate(-7deg);
            }
        }
    }

    @include for-md {
        .inner-container {
            padding: 30px 160px 50px 30px;
        }

        .contacts {
            flex-direction: column;
            gap: 30px;
        }
    }

    @include for-sm {

        .inner-container {
            padding: 30px 80px 30px 30px;

            &:after {
                $size: 30px;
                border: $size solid transparent;
                border-top: $size solid $color-black-text;
                border-right: $size solid $color-black-text;
            }
        }

        .block-contacts {
            margin-top: 30px;
            padding-left: 30px;
        }

        .contacts {
            margin-top: 30px;
        }


        .phone-container,
        .mail,
        .address {

            &:after {
                width: 20px;
                height: 20px;
                left: -30px;
                top: 2px;
                background-size: cover;
            }
        }

    }
}
