.text {
  color: #17171c;
  font-family: din-2014, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 1.4;
}

.text sup {
  font-size: 16px;
}

@media screen and (width <= 700px) {
  .text {
    font-size: 18px;
  }

  .text sup {
    font-size: 10px;
  }
}

.header .footnote, .title {
  text-transform: uppercase;
  color: #17171c;
  font-family: din-2014, sans-serif;
  font-size: 34px;
  font-weight: 700;
  line-height: 1.4;
}

@media screen and (width <= 700px) {
  .header .footnote, .title {
    font-size: 24px;
  }
}

.header .slogan {
  text-transform: uppercase;
  color: #17171c;
  font-family: din-2014, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 1.4;
}

@media screen and (width <= 700px) {
  .header .slogan {
    font-size: 24px;
  }
}

.ps-section {
  padding-inline: 50px;
}

@media screen and (width <= 700px) {
  .ps-section {
    padding-inline: 30px;
  }
}

.ps-section.expanded .inner-container {
  margin-inline: -30px;
}

.ps-section .ps-content {
  max-width: 1340px;
  margin-inline: auto;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #fff;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

.header .site-logotype {
  transition: filter .3s ease-in-out;
}

.header .site-logotype:hover {
  filter: brightness(120%);
}

.ps-featured-clients .logotype-container {
  transition: box-shadow .3s ease-in-out;
}

.ps-featured-clients .logotype-container:hover {
  box-shadow: 0 4px 4px #0000001a, 0 15px 15px #00000014;
}

.ps-footer .mail, .ps-footer .phone {
  text-decoration: none;
  transition: filter .3s ease-out;
  position: relative;
}

.ps-footer .mail:before, .ps-footer .phone:before {
  content: "";
  background-color: currentColor;
  height: 1px;
  transition: width .3s ease-out;
  display: block;
  position: absolute;
  bottom: 2px;
  left: 0;
}

.ps-footer .mail:hover, .ps-footer .phone:hover {
  filter: brightness(110%);
  opacity: 1;
  text-decoration: none !important;
}

.ps-footer .phone:before {
  width: 0;
}

.ps-footer .phone:hover:before, .ps-footer .mail:before {
  width: 100%;
}

.ps-footer .mail:hover:before {
  width: 0;
}

.flag {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 1em;
  height: .7em;
  margin-bottom: -1px;
  display: inline-block;
}

.flag.de {
  background-image: url("de.b0e9e601.svg");
}

.flag.dk {
  background-image: url("dk.361dd3df.svg");
}

.flag.es {
  background-image: url("es.f05d2414.svg");
}

.flag.fr {
  background-image: url("fr.1413557a.svg");
}

.flag.ge {
  background-image: url("ge.eaadd604.svg");
}

.flag.kz {
  background-image: url("kz.1d8729e8.svg");
}

.flag.lv {
  background-image: url("lv.70e45e17.svg");
}

.flag.pl {
  background-image: url("pl.add10ebb.svg");
}

.flag.tr {
  background-image: url("tr.6a7927a1.svg");
}

a[href][target="_blank"] {
  background-image: url("arrow_outward.caea92c2.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 1em;
  padding-right: calc(1em + 4px);
  display: inline-block;
}

.title, .text {
  margin: 0;
}

img {
  display: block;
}

.nbsp-container {
  align-items: center;
  gap: .3em;
  display: inline-flex;
}

.header {
  padding-block: 50px;
}

.header .site-logotype img {
  display: block;
}

.header .inner-wrapper {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

@keyframes moving {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 0 85px;
  }
}

.header .footnote {
  color: #cb2027;
  text-align: right;
  padding-right: 30px;
  line-height: 1.2;
  position: relative;
}

.header .footnote:after {
  content: "";
  background-image: url("uc.2f787d0b.svg");
  width: 10px;
  height: 85px;
  animation: 10s linear infinite moving;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.header .footnote em {
  color: #17171c;
  font-style: normal;
  font-weight: 400;
}

.header .slogan {
  margin: 35px 0 0;
}

@media screen and (width <= 1100px) {
  .header .inner-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }

  .header .footnote {
    text-align: center;
    padding-right: 0;
  }

  .header .footnote br {
    display: none;
  }

  .header .footnote:after {
    content: none;
  }

  .header .slogan {
    text-align: center;
    margin-top: 50px;
  }
}

@media screen and (width <= 700px) {
  .header {
    padding-bottom: 30px;
    padding-inline: 20px;
  }

  .header .site-logotype img {
    width: 260px;
    height: 45px;
  }

  .header .inner-wrapper {
    gap: 30px;
  }

  .header .slogan {
    margin-top: 30px;
  }

  .header .slogan br {
    display: none;
  }
}

.ps-home-hero .inner-container {
  position: relative;
}

.ps-home-hero .home-hero-bg {
  object-fit: cover;
  object-position: left 92%;
  width: 100%;
  height: 100%;
  position: absolute;
}

.ps-home-hero .inner-wrapper {
  justify-content: flex-end;
  align-items: flex-end;
  height: 680px;
  padding-inline: 30px;
  display: flex;
}

.ps-home-hero .field-of-activity {
  backdrop-filter: blur(5px);
  background: #17171ce6;
  border-bottom: 10px solid #cb2027;
  max-width: 720px;
  margin-bottom: -10px;
  padding: 50px;
}

.ps-home-hero .field-of-activity .title, .ps-home-hero .field-of-activity .text {
  color: #fff;
}

.ps-home-hero .field-of-activity .title {
  margin-bottom: 20px;
}

@media screen and (width <= 1100px) {
  .ps-home-hero .home-hero-bg {
    object-position: left 90%;
    height: 680px;
    position: static;
  }

  .ps-home-hero .inner-wrapper {
    height: auto;
  }

  .ps-home-hero .field-of-activity {
    max-width: none;
    margin-inline: -30px;
  }
}

@media screen and (width <= 700px) {
  .ps-home-hero .home-hero-bg {
    object-position: 20% 90%;
  }

  .ps-home-hero .field-of-activity {
    padding: 30px;
  }

  .ps-home-hero .field-of-activity .title {
    margin-bottom: 10px;
  }
}

.ps-about-us {
  margin-block: 50px 100px;
}

.ps-about-us .inner-container {
  gap: 50px;
  display: flex;
}

.ps-about-us .inner-wrapper > :first-child {
  margin-top: 0;
}

.ps-about-us .inner-wrapper > :last-child {
  margin-bottom: 0;
}

.ps-about-us .title {
  margin-top: 50px;
  margin-bottom: 20px;
}

.ps-about-us .info {
  flex-direction: column;
  gap: .7em;
  display: flex;
}

.ps-about-us a[href] {
  color: #cb2027;
}

.ps-about-us .certificates a img {
  transition: transform .3s ease-in-out;
}

.ps-about-us .certificates a:hover img {
  transform: translateY(-5px);
}

.ps-about-us .certificates .text {
  color: #000;
  font-weight: 700;
}

.ps-about-us .certificates .inner-wrapper {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 20px 60px;
  margin-top: 20px;
  display: flex;
}

.ps-about-us .certificates .c-1, .ps-about-us .certificates .c-2, .ps-about-us .certificates .c-3 {
  width: auto;
  height: 65px;
}

.ps-about-us .collage {
  grid-gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.ps-about-us .collage .inner-wrapper {
  align-items: flex-start;
  gap: 20px;
  margin-inline: -20px;
  display: flex;
}

.ps-about-us .collage .img-3 {
  margin-top: -106px;
}

.ps-about-us .collage .img-2, .ps-about-us .collage .img-3 {
  box-shadow: 0 1px 1px #0000001a, 0 5px 5px #0000000d;
}

@media screen and (width <= 1100px) {
  .ps-about-us {
    margin-top: 60px;
  }

  .ps-about-us .inner-container {
    flex-direction: column;
  }

  .ps-about-us .collage {
    align-items: center;
  }
}

@media screen and (width <= 700px) {
  .ps-about-us {
    margin-top: 40px;
  }

  .ps-about-us .title {
    margin-bottom: 10px;
  }

  .ps-about-us .certificates {
    margin-top: 30px;
  }

  .ps-about-us .certificates .inner-wrapper {
    gap: 20px 30px;
  }

  .ps-about-us .certificates .c-1, .ps-about-us .certificates .c-2 {
    height: 60px;
  }

  .ps-about-us .certificates .c-3 {
    height: 65px;
  }

  .ps-about-us .collage, .ps-about-us .collage .inner-wrapper {
    gap: 10px;
  }

  .ps-about-us .collage .img-1 {
    width: 294px;
    height: 235px;
  }

  .ps-about-us .collage .img-2 {
    width: 146px;
    height: 128px;
  }

  .ps-about-us .collage .img-3 {
    width: 158px;
    height: 229px;
    margin-top: -55px;
  }
}

.ps-featured-clients {
  margin-block: 100px 20px;
}

.ps-featured-clients .inner-container {
  backdrop-filter: blur(10px);
  background: #f0f6f9;
  padding: 50px;
}

.ps-featured-clients .inner-grid {
  grid-gap: 40px 30px;
  grid-template-columns: repeat(4, 1fr);
  display: grid;
}

.ps-featured-clients .title {
  margin-bottom: 50px;
}

.ps-featured-clients .logotype-container {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  height: 160px;
  padding-inline: 10px;
  display: flex;
  box-shadow: 0 1px 1px #0000001a, 0 5px 5px #0000000d;
}

.ps-featured-clients .logotype-container .logotype {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.ps-featured-clients .text {
  margin-top: 20px;
}

@media screen and (width <= 1100px) {
  .ps-featured-clients .inner-container {
    margin-inline: -30px;
  }

  .ps-featured-clients .inner-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (width <= 700px) {
  .ps-featured-clients {
    margin-bottom: 10px;
  }

  .ps-featured-clients .title {
    margin-bottom: 30px;
  }

  .ps-featured-clients .inner-container {
    padding: 30px 30px 50px;
  }

  .ps-featured-clients .logotype-container {
    height: 140px;
  }

  .ps-featured-clients .text {
    margin-top: 10px;
  }
}

@media screen and (width <= 480px) {
  .ps-featured-clients .inner-grid {
    grid-gap: 30px;
    grid-template-columns: 1fr;
  }
}

.ps-footer * {
  color: #fff;
}

.ps-footer .inner-container {
  background-color: #cb2027;
  padding: 50px;
  position: relative;
}

.ps-footer .inner-container:after {
  content: "";
  border: 62px solid #17171c;
  border-color: #17171c #17171c #0000 #0000;
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
}

.ps-footer .title {
  max-width: 750px;
}

.ps-footer .block-contacts {
  margin-top: 50px;
  padding-left: 50px;
}

.ps-footer .contacts {
  gap: 110px;
  margin-top: 20px;
  display: flex;
}

.ps-footer .contacts .inner-wrapper {
  flex-direction: column;
  gap: 30px;
  display: flex;
}

.ps-footer .name {
  font-weight: 700;
}

.ps-footer .phone-container, .ps-footer .mail, .ps-footer .address {
  cursor: pointer;
  position: relative;
}

.ps-footer .phone-container:after, .ps-footer .mail:after, .ps-footer .address:after {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  top: 0;
  left: -50px;
}

.ps-footer .phone {
  text-decoration: none;
}

.ps-footer .phone-container {
  gap: 10px;
  display: flex;
}

.ps-footer .phone-container:after {
  background-image: url("phone.54066d9d.svg");
}

.ps-footer .mail {
  max-width: max-content;
}

.ps-footer .mail:after {
  background-image: url("mail.2adf718c.svg");
  top: 5px;
}

.ps-footer .address {
  text-decoration: none;
}

.ps-footer .address:after {
  background-image: url("address.8007b71b.svg");
  transition: transform .3s ease-in-out;
}

.ps-footer .address:hover:after {
  transform: rotate(-7deg);
}

@media screen and (width <= 1100px) {
  .ps-footer .inner-container {
    padding: 30px 160px 50px 30px;
  }

  .ps-footer .contacts {
    flex-direction: column;
    gap: 30px;
  }
}

@media screen and (width <= 700px) {
  .ps-footer .inner-container {
    padding: 30px 80px 30px 30px;
  }

  .ps-footer .inner-container:after {
    border: 30px solid #17171c;
    border-color: #17171c #17171c #0000 #0000;
  }

  .ps-footer .block-contacts {
    margin-top: 30px;
    padding-left: 30px;
  }

  .ps-footer .contacts {
    margin-top: 30px;
  }

  .ps-footer .phone-container:after, .ps-footer .mail:after, .ps-footer .address:after {
    background-size: cover;
    width: 20px;
    height: 20px;
    top: 2px;
    left: -30px;
  }
}
/*# sourceMappingURL=index.5e6d3aae.css.map */
